import "./About.css";
import React from "react";
import { Card, CardMedia, CardContent, CardActions, Typography, IconButton, Collapse } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GetChefs } from "../components/BackendConnector";

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function About() {
    const [chefs, setChefs] = React.useState([]);

    const handleChefExpandClick = index => e => {
        let newChefs = [...chefs];
        newChefs[index].isExpanded = !chefs[index].isExpanded
        setChefs(newChefs)
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            backgroundColor: 'secondary.light'
        }}>
            {chefs.map((chef, index) => (
                <Card sx={{ minWidth: "200px", width: '30%', marginBottom: 'auto', marginLeft: 'auto', marginRight: 'auto' }}>
                    <CardMedia
                        sx={{ height: 333 }}
                        title={chef["firstName"]}
                        image={chef["imageUrl"]}
                    />
                    <CardContent sx={{ paddingBottom: 0 }}>
                        <Typography variant="h3" fontFamily={"AuthorizedSignature"} color={'secondary.main'}>
                            {chef["firstName"]}
                        </Typography>
                        <Typography variant="h4" fontFamily={"AuthorizedSignature"} color={'secondary.main'}>
                            {chef["lastName"]}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ margin: 0, padding: 0 }}>
                        <ExpandMore
                            expand={chefs[index].isExpanded}
                            onClick={handleChefExpandClick(index)}
                            aria-expanded={chefs[index].isExpanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </CardActions>
                    <Collapse in={chefs[index].isExpanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography variant="body2" color="primary.dark">
                                {chef["description"]}
                            </Typography>
                        </CardContent>
                    </Collapse>
                </Card>
            ))}
            <GetChefs onChefsLoaded={(loadedChefs) => setChefs(loadedChefs)} />
        </Box>
    );
}