import "./Book.css";
import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

export default class Book extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker />
                </LocalizationProvider>
            </Box>
        );
    }
}
