import "./Home.css";
import React from "react";
import { Typography, Button, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/system";

export default class Home extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Box sx={{
                backgroundColor: 'secondary.main'
            }}>
                <Typography variant="h6" fontFamily={"Century"} align="left" color={'#f5b342'}>
                    Mi Manera is een uniek micro-restaurant met 16 plaatsen (voor een groep tot maximaal 20 plaatsen) in het absolute centrum van Mechelen. De eetzaal wasemt een unieke Spaanse sfeer uit en bied je een uitzonderlijk zicht op St Romboutstoren, Margareta Van Oostenrijk en Grote Markt. Nergens beter toeven in Mechelen.
                    <br /><br />
                    De keuken wordt wekelijks ter beschikking gesteld aan een gepassioneerde 'amateur' kok of 'super' chef, die ons telkens een vast menu voorschotelt : verrassend persoonlijk maar steeds met een (kleine) mediterraanse toets..
                    <br /><br />
                    Kom eens proberen en wordt ambassadeur van een van onze chefs.
                    In een rotatie van 4 tot 6 weken komen ze terug !
                    <br /><br />
                    Alleen op donderdag, vrijdag en zaterdag
                </Typography>
                <Box width="100%" align="center"><div className="Marisa" /></Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-around',
                    alignItems: 'baseline',
                    p: 1,
                    m: 1,
                }}>
                    <Link href="http://maps.google.com/?q=Schoenmarkt 2, Mechelen, 2000" variant="h6" color={'#f5b342'} textAlign={"center"}>
                        Schoenmarkt 2 Mechelen
                    </Link>
                    <Typography variant="h6" color={'#f5b342'} textAlign={"center"}>
                        Geopend op donderdag, vrijdag en zaterdag
                    </Typography>
                    <Link href="mailto:contact@mi-manera.be" variant="h6" color={'#f5b342'} textAlign={"center"}>
                        contact@mi-manera.be
                    </Link>
                    <Link href="tel:0032478273572" variant="h6" color={'#f5b342'} textAlign={"center"}>
                        0032/478.27.35.72
                    </Link>
                </Box>
            </Box>
        );
    }
}
