import './App.css';
import * as React from 'react';
import { Box, AppBar, Toolbar, Drawer, Typography, IconButton, List, ListItem } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { BrowserRouter as Router, Link, Outlet, Route, Routes } from "react-router-dom";
import styled from '@emotion/styled';
import drawerBackground from './assets/drawer-background.jpg'

let theme = createTheme({
  palette: {
    primary: {
      light: '#FFFFFF',
      main: '#10DCDC',
      dark: '#000000'
    },
    secondary: {
      light: '#BC5B34',
      main: '#9F2E23',
      dark: '#24406F'
    }
  },
  typography: {
    fontFamily: [
      'AuthorizedSignature',
      'Century'
    ]
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'dashed' },
          style: {
            textTransform: 'none',
            border: `2px dashed black`,
            backgroundColor: '#BC5B34',
            ":disabled": {
              backgroundColor: '#9F2E23'
            }
          },
        }, {
          props: { variant: 'full' },
          style: {
            textTransform: 'none',
            border: `2px solid black`,
            backgroundColor: '#f5b342'
          },
        },
      ],
    },
  },
});
theme = responsiveFontSizes(theme);

function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [dynamicBackgroundColor, setBackgroundColor] = React.useState('secondary.main');
  const [dynamicLogoColor, setLogoColor] = React.useState('primary.light');

  const handleDrawerToggle = () => {
    if (window.innerWidth < 900) {
      setMobileOpen(!mobileOpen);
    }
  };

  const NavigationButton = styled(Link)(({ theme }) => ({
    width: '100%',
    padding: '5px',
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    fontFamily: 'Century',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    textAlign: 'center'
  }));

  const drawerWidth = 240;

  const drawer = (
    <List>
      <ListItem key="Home" disablePadding>
        <NavigationButton to="/" onClick={() => {
          handleDrawerToggle(false)
          setBackgroundColor('secondary.main')
          setLogoColor('primary.light')
        }}>
          <Typography variant="h6">
            Home
          </Typography>
        </NavigationButton>
      </ListItem>

      <ListItem key="Menu" disablePadding>
        <NavigationButton to="/menu" onClick={() => {
          handleDrawerToggle(false)
          setBackgroundColor('primary.light')
          setLogoColor('primary.dark')
        }}>
          <Typography variant="h6">
            Menu/Reserveren
          </Typography>
        </NavigationButton>
      </ListItem>

      {/* <ListItem key="Reserveren" disablePadding>
        <NavigationButton to="/reserveren" onClick={() => {
          handleDrawerToggle(false)
          setBackgroundColor('secondary.dark')
          setLogoColor('primary.light')
        }}>
          <Typography variant="h6">
            Reserveren
          </Typography>
        </NavigationButton>
      </ListItem> */}

      <ListItem key="About" disablePadding>
        <NavigationButton to="/about" onClick={() => {
          handleDrawerToggle(false)
          setBackgroundColor('secondary.light')
          setLogoColor('primary.light')
        }}>
          <Typography variant="h6">
            De koks
          </Typography>
        </NavigationButton>
      </ListItem>
    </List>)

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          backgroundColor: dynamicBackgroundColor
        }}
      >
        <AppBar
          sx={{
            position: 'fixed',
            width: { md: `calc(100% - ${drawerWidth}px)` },
            ml: { md: `${drawerWidth}px` },
          }}
        >
          <Toolbar sx={{ display: { md: 'none' }, backgroundColor: dynamicBackgroundColor }}>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h3" fontFamily={"AuthorizedSignature"} color={dynamicLogoColor}>
              Mi Manera
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ width: { drawerWidth }, flexShrink: { md: 0 } }}>
          <Drawer
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'block', md: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)), url(${drawerBackground})`,
                backgroundPosition: 'right',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              },
            }}
          >
            <Box sx={{ textAlign: "center", justifyContent: 'center', marginTop: '10px' }}>
              <Typography variant="h3" fontFamily={"AuthorizedSignature"} color={'secondary.main'}>
                Mi Manera
              </Typography>
            </Box>
            <Toolbar />
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'none', md: 'block' },
              width: drawerWidth,
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2)), url(${drawerBackground})`,
                backgroundPosition: 'right',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }
            }}
          >
            <Box sx={{ textAlign: "center", justifyContent: 'center', marginTop: '10px' }}>
              <Typography variant="h3" fontFamily={"AuthorizedSignature"} color={'secondary.main'}>
                Mi Manera
              </Typography>
            </Box>
            <Toolbar />
            {drawer}
          </Drawer>
        </Box>
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider >
  );
}

export default App;
