import React from "react";
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Link, Box, Select, MenuItem, Snackbar } from "@mui/material";
import { CreateReservation } from "./BackendConnector";
import zIndex from "@mui/material/styles/zIndex";

export default function BookDialog(props) {
    const [reservation, setReservation] = React.useState({});
    const [finished, setFinished] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const handleChange = (event) => {
        const { name, value } = event.target;
        setReservation({
            ...reservation,
            [name]: value,
        });
    };

    const finishUp = () => {
        setFinished(true)
        setReservation({})
        props.setLoading(false)
    }

    const handleError = (error) => {
        props.setLoading(false)
        setErrorMessage(error)
    }

    return (
        <Dialog sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props.dialogOpen} onClose={() => props.setDialogOpen(false)}>
            {finished ?
                (<DialogTitle>Bedankt voor de reservatie, we contacteren je zo snel mogelijk</DialogTitle>) :
                (<Box>
                    <DialogTitle>Reserveren voor {props.date}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Vul al je gegevens in. We contacteren je zo snel mogelijk voor de bevestiging.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label="Voor- en achternaam"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="email"
                            name="email"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="phone"
                            name="phone"
                            label="GSM"
                            type="tel"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                        />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <DialogContentText sx={{ marginTop: "auto", marginBottom: "auto" }} >
                                Aantal personen
                            </DialogContentText>
                            <Select
                                name="amount"
                                onChange={handleChange}>
                                {[...Array(props.openSpots)].map((x, i) =>
                                    <MenuItem value={i + 1}>{i + 1}</MenuItem>
                                )}
                            </Select>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setReservation({})
                            props.setDialogOpen(false)
                        }}>Annuleer</Button>
                        <Button onClick={() => {
                            props.setLoading(true)
                            CreateReservation(props.id, reservation, handleError, finishUp)
                        }}>Reserveren</Button>
                    </DialogActions>
                    <Box marginLeft={"15%"} marginRight={"15%"}>
                        <Typography variant="body5" color={'primary.dark'} >
                            PS: We hebben maar een beperkt aantal plaatsen. Voor een grotere groep (tot max 20 pers.) kan je best even contact opnemen:<br />
                        </Typography>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                columnGap: "1vw",
                                marginBottom: "10px"
                            }}>
                            <Link href="mailto:contact@mi-manera.be" variant="body5" color={'primary.dark'}>
                                contact@mi-manera.be
                            </Link>
                            <Typography variant="body5" color={'primary.dark'} >
                                &nbsp;of&nbsp;
                            </Typography>
                            <Link href="tel:0032478273572" variant="body5" color={'primary.dark'}>
                                0032/478.27.35.72
                            </Link>
                        </Box>
                    </Box>
                    <Snackbar
                        sx={{ height: "100%" }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        autoHideDuration={1000}
                        open={errorMessage}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id"> {errorMessage} </span>}
                        onClose={() => setErrorMessage("")}
                    />
                </Box>)}
        </Dialog>
    )
}