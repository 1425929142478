import "./Menu.css";
import React from "react";
import { Box, Typography, IconButton, Button } from "@mui/material";
import BookDialog from "../components/BookDialog";
import { GetMenus } from "../components/BackendConnector";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function About() {
    const [menus, setMenus] = React.useState([]);
    const [activeMenu, setActiveMenu] = React.useState(0);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const arrowForward = ">";
    const arrowBackward = "<";

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                rowGap: "2vh",
                backgroundColor: "primary.light"
            }}>
            <Typography variant="h2" fontFamily={"AuthorizedSignature"} color={'primary.dark'}>
                Menu
            </Typography>

            {menus.length > 0 ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        rowGap: "2vh"
                    }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            columnGap: "1vw"
                        }}>
                        <IconButton
                            color="inherit"
                            disabled={activeMenu === 0}
                            onClick={() => setActiveMenu(activeMenu - 1)}
                            sx={{ backgroundColor: "secondary.light", paddingRight: 3, paddingLeft: 3 }}
                        >
                            <Typography variant="h3" fontFamily={"AuthorizedSignature"} color={activeMenu === 0 ? 'gray' : 'primary.dark'}>
                                {arrowBackward}
                            </Typography>
                        </IconButton>
                        <Typography variant="h3" fontFamily={"AuthorizedSignature"} color={'primary.dark'}>
                            {menus[activeMenu].datum}
                        </Typography>
                        <IconButton
                            color="inherit"
                            disabled={activeMenu === menus.length - 1}
                            onClick={() => setActiveMenu(activeMenu + 1)}
                            sx={{ backgroundColor: "secondary.light", paddingRight: 3, paddingLeft: 3 }}
                        >
                            <Typography variant="h3" fontFamily={"AuthorizedSignature"} color={activeMenu === menus.length - 1 ? 'gray' : 'primary.dark'}>
                                {arrowForward}
                            </Typography>
                        </IconButton>
                    </Box>
                    {/* <Button variant="full" onClick={() => setDialogOpen(true)}>
                        Bekijk alle data
                    </Button> */}
                    {menus[activeMenu].openSpots > 0 ?
                        (<Button variant="dashed" onClick={() => setDialogOpen(true)}>
                            Reserveer voor deze dag (nog {menus[activeMenu].openSpots} plekjes beschikbaar)
                        </Button>) : <Button variant="dashed" disabled >
                            Volzet
                        </Button>}
                    <BookDialog id={menus[activeMenu].id} date={menus[activeMenu].datum} openSpots={menus[activeMenu].openSpots} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} setLoading={setLoading} />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}>
                        <Typography variant="h4" fontFamily={"AuthorizedSignature"} color={'primary.dark'}>
                            Chef van de dag&nbsp;&nbsp;
                        </Typography>
                        <Typography variant="h2" fontFamily={"AuthorizedSignature"} color={'primary.dark'}>
                            {menus[activeMenu].chef}
                        </Typography>
                    </Box>
                    <Box component="img" sx={{ width: '70%' }} src={menus[activeMenu].menuUrl} />
                </Box>
            ) : null
            }
            <GetMenus onMenusLoaded={(loadedMenus) => setMenus(loadedMenus)} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box >
    );
}
