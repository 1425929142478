import React from "react";
import { createRoot } from "react-dom/client";
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from "./App";
import Home from "./pages/Home";
import Book from "./pages/Book";
import About from "./pages/About";
import { CssBaseline } from "@mui/material";
import { Helmet } from "react-helmet";
import Menu from "./pages/Menu";
import Admin from "./pages/Admin";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "menu",
        element: <Menu />
      },
      {
        path: "reserveren",
        element: <Book />
      },
      {
        path: "about",
        element: <About />
      },
      {
        path: "marie",
        element: <Admin />
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <CssBaseline />
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Restaurant",
          "name": "Mi Manera",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Schoenmarkt 2",
            "addressLocality": "Mechelen",
            "addressRegion": "Antwerpen",
            "postalCode": "2800",
            "addressCountry": "BE"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 51.027751157510906,
            "longitude": 4.479613765399713
          },
          "url": "https://mi-manera.be",
          "email": "contact@mi-manera.be",
          "telephone": "+32478273572",
          "servesCuisine": "Spanish",
          "priceRange": "$$",
          "acceptsReservations": "True",
          "menu": "https://www.mi-manera.be/menu",
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Thursday",
                "Friday",
                "Saturday"
              ],
              "opens": "18:00",
              "closes": "23:00"
            }
          ]
        })}
      </script>
    </Helmet>
    <RouterProvider router={router} />
  </React.StrictMode>
);