import { useEffect } from "react";
import { Amplify, API, Storage } from "aws-amplify";
import awsconfig from "../.aws-config/awsconfig.json";

Amplify.configure(awsconfig);

const GetMenus = (props) => {
  useEffect(() => {
    API.get("mi-manera", "/menus", {}).then((menus) => {
      props.onMenusLoaded(menus.map(obj => ({ ...obj, isExpanded: false })));
    })
  }, []);

  return <div />
};

const GetMenusAdmin = async (password, setMenus, setLoading) => {
  setLoading(true);
  API.get("mi-manera", "/menus-admin", { headers: { pwd: password } })
    .then((response) => {
      console.log(response);
      setMenus(response);
    })
    .catch((error) => { })
    .finally(() => {
      setLoading(false);
    });
};

const UpdateMenuAdmin = async (password, menu, setLoading) => {
  let newMenu = null
  if (menu.newMenu) {
    newMenu = await bufferToBase64(menu.newMenu)
  }
  setLoading(true);
  API.post("mi-manera", "/menus-admin", { headers: { pwd: password }, body: { ...menu, newMenu: newMenu, reservations: menu.reservations ? menu.reservations : [], totalSpots: parseInt(menu.totalSpots) } })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      setLoading(false);
    });
};

const GetChefs = (props) => {
  useEffect(() => {
    API.get("mi-manera", "/chefs", {}).then((chefs) => {
      props.onChefsLoaded(chefs.map(obj => ({ ...obj, isExpanded: false })));
    })
  }, []);

  return <div />
};

const GetChefsAdmin = async (password, setChefs, setLoading) => {
  setLoading(true);
  API.get("mi-manera", "/chefs-admin", { headers: { pwd: password } })
    .then((response) => {
      setChefs(response);
    })
    .catch((error) => { })
    .finally(() => {
      setLoading(false);
    });
};

const UpdateChefAdmin = async (password, chef, setLoading) => {
  let newPhoto = null
  if (chef.newPhoto) {
    newPhoto = await bufferToBase64(chef.newPhoto)
  }
  setLoading(true);
  API.post("mi-manera", "/chefs-admin", { headers: { pwd: password }, body: { ...chef, newPhoto: newPhoto } })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      setLoading(false);
    });
};

const CreateReservation = async (id, reservation, handleError, finishUp) => {
  API.post("mi-manera", "/reservations", { body: { ...reservation, 'id': id } })
    .then((response) => {
      console.log(response);
      finishUp();
    })
    .catch((error) => {
      console.error(error);
      handleError(error.response.data);
    });
};

async function bufferToBase64(buffer) {
  const base64url = await new Promise(r => {
    const reader = new FileReader()
    reader.onload = () => r(reader.result)
    reader.readAsDataURL(new Blob([buffer]))
  });
  return base64url.slice(base64url.indexOf(',') + 1);
}

export {
  GetChefs,
  GetChefsAdmin,
  UpdateChefAdmin,
  GetMenus,
  GetMenusAdmin,
  UpdateMenuAdmin,
  CreateReservation
};
