import React from "react";
import { GetChefsAdmin, GetMenusAdmin } from "../components/BackendConnector";
import { Box, TextField, Button, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MenuAdmin from "../components/MenuAdmin";
import ChefAdmin from "../components/ChefAdmin";
import Divider from '@mui/material/Divider';

export default function Admin() {
    const [password, setPassword] = React.useState(null);
    const [menus, setMenus] = React.useState(null);
    const [chefs, setChefs] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    };

    const addEmptyMenu = () => {
        var maxMenuId = 0
        menus.forEach((menu) => {
            if (menu.id > maxMenuId) {
                maxMenuId = menu.id
            }
        })
        setMenus(
            [
                ...menus,
                {
                    id: maxMenuId + 1,
                    datum: 'datum',
                    totalSpots: 0,
                    isActive: true
                }
            ]);
    };

    const addEmptyChef = () => {
        var maxChefId = 0
        chefs.forEach((chef) => {
            if (chef.id > maxChefId) {
                maxChefId = chef.id
            }
        })
        setChefs(
            [
                ...chefs,
                {
                    id: maxChefId + 1,
                    firstName: 'voornaam',
                    lastName: 'achternaam',
                    description: 'omschrijving',
                    isActive: 0
                }
            ]);
    };

    return (
        <Box>
            {menus && chefs ?
                (
                    <Box>
                        <Typography variant="h3" color={'primary.dark'}>
                            Menus
                        </Typography>
                        {menus.map((menu, index) => (
                            menu.isActive ?
                                <MenuAdmin menu={menu} chefs={chefs} password={password} setLoading={setLoading} />
                                : null
                        ))}
                        <Button onClick={addEmptyMenu}>
                            Nieuw menu aanmaken
                        </Button>
                        <Divider sx={{ borderBottomWidth: 10, bgcolor: "primary.dark" }} />
                        <Typography variant="h3" color={'primary.dark'}>
                            Chefs
                        </Typography>
                        {chefs.map((chef, index) => (
                            <ChefAdmin chef={chef} password={password} setLoading={setLoading} />
                        ))}
                        <Button onClick={addEmptyChef}>
                            Nieuwe chef aanmaken
                        </Button>
                    </Box >
                ) :
                <Box>
                    <TextField
                        placeholder="password" onChange={handlePasswordChange} />
                    <Button onClick={() => {
                        GetMenusAdmin(password, setMenus, setLoading)
                        GetChefsAdmin(password, setChefs, setLoading)
                    }}>
                        Login
                    </Button>
                </Box >}

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box >
    );
}