import React from "react";
import { FormGroup, TextField, Typography, Button, FormControlLabel, Checkbox, Box } from "@mui/material";
import { UpdateChefAdmin } from "../components/BackendConnector";
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

export default function ChefAdmin(props) {
    const [chefValues, setChefValues] = React.useState(props.chef);

    const arrow = "=>";

    const handleFileChange = (event) => {
        setChefValues({
            ...chefValues,
            newPhoto: event.target.files[0],
            newPhotoType: event.target.files[0].type
        });
    };

    const removeNewFile = () => {
        setChefValues({
            ...chefValues,
            newPhoto: null,
            newPhotoType: null
        });
    };

    const handleTextFieldChange = (event) => {
        const { name, value } = event.target;
        setChefValues({
            ...chefValues,
            [name]: value,
        });
    };

    const handleCheckboxChange = (event, isActive) => {
        const { name } = event.target;
        setChefValues({
            ...chefValues,
            [name]: isActive ? 1 : 0,
        });
    };

    return (
        <form>
            <FormGroup>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <Box component="img" sx={{ width: "20%" }} src={props.chef.image} />
                    <Typography variant="h4" color={'primary.dark'}>
                        {arrow}
                    </Typography>
                    {chefValues.newPhoto ?
                        <Box>
                            <Box component="img" sx={{ width: "20%" }} src={URL.createObjectURL(chefValues.newPhoto)} />
                            <CloseIcon onClick={removeNewFile} />
                        </Box>
                        :
                        <input type='file' onChange={handleFileChange} />
                    }
                </Box>
                <TextField name="firstName" placeholder={props.chef.firstName} onChange={handleTextFieldChange} />
                <TextField name="lastName" placeholder={props.chef.lastName} onChange={handleTextFieldChange} />
                <TextField name="description" placeholder={props.chef.description} onChange={handleTextFieldChange} />
                <FormControlLabel control={<Checkbox name="isActive" value="isActive" checked={chefValues.isActive} onChange={handleCheckboxChange} />}
                    label="Is Active"
                />
                <Button sx={{ color: "primary.dark" }} onClick={() => UpdateChefAdmin(props.password, chefValues, props.setLoading)}>Chef opslaan</Button>
            </FormGroup>
            <Divider sx={{ borderBottomWidth: 5, bgcolor: "primary.dark" }} />
        </form>
    )
}