import React from "react";
import { FormGroup, Box, Typography, TextField, Button, Select, MenuItem, Input, ToggleButton } from "@mui/material";
import { UpdateMenuAdmin } from "./BackendConnector";
import ReservationAdmin from "../components/ReservationAdmin";
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

export default function MenuAdmin(props) {
    const [menuValues, setMenuValues] = React.useState(props.menu);

    const arrow = "=>";

    const handleFileChange = (event) => {
        setMenuValues({
            ...menuValues,
            newMenu: event.target.files[0],
            newMenuType: event.target.files[0].type
        });
    };

    const removeNewFile = () => {
        setMenuValues({
            ...menuValues,
            newMenu: null,
            newMenuType: null
        });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setMenuValues({
            ...menuValues,
            [name]: value,
        });
    };

    const handleChefChange = (event) => {
        const { name, value } = event.target;
        var chefName = "";
        props.chefs.forEach((chef) => {
            if (chef.id == value) {
                chefName = chef.firstName
            }
        })
        setMenuValues({
            ...menuValues,
            'chefId': value,
            'chefName': chefName,
        });
    }

    const changeReservation = (id, reservation) => {
        var allReservations = menuValues.reservations;
        allReservations[id] = reservation
        setMenuValues({
            ...menuValues,
            'reservations': allReservations
        })
    }

    const removeReservation = (id) => {
        var allReservations = menuValues.reservations;
        allReservations.splice(id, 1)
        setMenuValues({
            ...menuValues,
            'reservations': allReservations
        })
        console.log(menuValues.reservations)
    }

    return (
        <form>
            <FormGroup>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <Box component="img" sx={{ width: "20%", margin: "auto" }} src={props.menu.menu} />
                    <Typography sx={{ width: "20%", margin: "auto" }} variant="h4" color={'primary.dark'}>
                        {arrow}
                    </Typography>
                    {menuValues.newMenu ?
                        <Box sx={{ width: "40%", margin: "auto" }}>
                            <Box component="img" sx={{ width: "20%" }} src={URL.createObjectURL(menuValues.newMenu)} />
                            <CloseIcon onClick={removeNewFile} />
                        </Box>
                        :
                        <Input sx={{ width: "40%", margin: "auto" }} type='file' onChange={handleFileChange} />
                    }
                </Box>
                <TextField type="date" name="datum" defaultValue={props.menu.datum} onChange={handleChange} />
                <Select
                    value={menuValues.chefId}
                    name="chef"
                    onChange={handleChefChange}>
                    {props.chefs ? (props.chefs.map((chef, index) => (
                        <MenuItem value={chef.id}>{chef.id} - {chef.firstName}</MenuItem>
                    ))) : null}
                </Select>
                <TextField type="number" name="totalSpots" placeholder={"Totaal open plekjes: " + props.menu.totalSpots} onChange={handleChange} />
                <Typography variant="h5" color={'primary.dark'} margin={"auto"}>
                    Reservaties
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <Typography sx={{ width: "19%", margin: "auto" }} variant="body3" color={'primary.dark'} >
                        Naam
                    </Typography>
                    <Typography sx={{ width: "19%", margin: "auto" }} variant="body3" color={'primary.dark'} >
                        Email
                    </Typography>
                    <Typography sx={{ width: "19%", margin: "auto" }} variant="body3" color={'primary.dark'} >
                        Telefoon
                    </Typography>
                    <Typography sx={{ width: "19%", margin: "auto" }} variant="body3" color={'primary.dark'}>
                        Aantal
                    </Typography>
                    <Typography sx={{ width: "19%", margin: "auto" }} variant="body3" color={'primary.dark'}>
                        Geaccepteerd?
                    </Typography>
                    <Typography sx={{ width: "4%", margin: "auto" }} variant="body3" color={'primary.dark'}>
                        Weg ermee
                    </Typography>
                </Box>
                {props.menu.reservations ? (props.menu.reservations.map((reservation, index) => (
                    <Box>
                        <ReservationAdmin id={index} reservation={reservation} changeReservation={changeReservation} removeReservation={removeReservation} />
                        <Divider sx={{ borderBottomWidth: 2, bgcolor: "primary.dark" }} />
                    </Box>
                ))) : null}
                <Divider sx={{ borderBottomWidth: 2, bgcolor: "primary.dark" }} />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <Button sx={{ color: "primary.dark", margin: "auto" }} onClick={() => UpdateMenuAdmin(props.password, { ...menuValues, isActive: false }, props.setLoading)}>Menu afsluiten</Button>
                    <Button sx={{ color: "primary.dark", margin: "auto" }} onClick={() => UpdateMenuAdmin(props.password, menuValues, props.setLoading)}>Menu opslaan</Button>
                </Box>
            </FormGroup>
            <Divider sx={{ borderBottomWidth: 5, bgcolor: "primary.dark" }} />
        </form >
    )
}