import React from "react";
import { Box, Typography, ToggleButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function ReservationAdmin(props) {
    const acceptedText = "Geaccepteerd"
    const declinedText = "Accepteren"

    const removeReservation = () => {
        props.removeReservation(props.id)
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            <Typography sx={{ width: "19%", margin: "auto" }} variant="body3" color={'primary.dark'} style={{ wordWrap: "break-word" }}>
                {props.reservation.name}
            </Typography>
            <Typography sx={{ width: "19%", margin: "auto" }} variant="body3" color={'primary.dark'} style={{ wordWrap: "break-word" }}>
                {props.reservation.email}
            </Typography>
            <Typography sx={{ width: "19%", margin: "auto" }} variant="body3" color={'primary.dark'} style={{ wordWrap: "break-word" }}>
                {props.reservation.phone}
            </Typography>
            <Typography sx={{ width: "19%", margin: "auto" }} variant="body3" color={'primary.dark'}>
                {props.reservation.amount} personen
            </Typography>
            <ToggleButton
                sx={{ width: "19%", margin: "auto" }}
                selected={props.reservation.accepted}
                color="primary"
                onChange={() => {
                    props.changeReservation(props.id, {
                        ...props.reservation,
                        'accepted': !props.reservation.accepted,
                    })
                }}
            >
                <Typography variant="body3" color={'primary.dark'}>
                    {props.reservation.accepted ? acceptedText : declinedText}
                </Typography>
            </ToggleButton>
            <CloseIcon sx={{ width: "4%", margin: "auto" }} onClick={removeReservation} />
        </Box>
    )
}